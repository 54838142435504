<template>
  <div class="contactsView">
    <div class="mask" v-if="tableData.length == 0 && searchValue == ''">
      <PlaceholderMap :placeholderType="1"></PlaceholderMap>
    </div>
    <template v-if="showHeader">
      <MainHeader></MainHeader>
    </template>
    <div class="viewMain" id="contacts-view">
      <div
        class="warningTips"
        :style="warningTipsCoordinates"
        v-if="addRepeatTips"
        id="warningTips"
      >
        {{ $t("contactsRepeatTips") }}
      </div>
      <div class="searchBox">
        <div class="goBack" @click="goBack" v-if="showGoBack">
          <img :src="require('../../../../assets/images/email/goBack.png')" />
        </div>
        <div class="searchInput">
          <el-input
            v-model="searchValue"
            :placeholder="placeholderIcon"
          ></el-input>
        </div>
      </div>
      <div
        class="addTips"
        v-if="!this.userClickAddToTheContact && tableData.length != 0"
      >
        <div class="tipButton" @click.stop="openTableheaderInput">
          <span class="iconfont_Me icon-tag2 iconStyle"></span>
          <span class="iconfont_Me icon-plus addIconStyle"></span>
        </div>
        <div
          class="tipButton"
          :class="{ active: addTipsActive }"
          v-if="tableheaderInput"
        >
          <el-input
            v-model="headerValue"
            @focus.stop="addTipsActive = true"
            @blur.stop="
              addTipsActive = false;
              tableheaderInput = false;
              addRepeatTips = false;
            "
            @keyup.stop.enter.native="addTableheader"
            maxlength="20"
            ref="tableheaderInput"
            id="addTipsInput"
          ></el-input>
        </div>
      </div>
      <div
        class="addContactStyle"
        v-waves
        @click.stop="addContact"
        v-if="!this.userClickAddToTheContact"
      >
        <span class="iconBox">
          <i class="iconfont_Me icon-plus iconStyle"></i>
        </span>
        {{ $t("addContact") }}
      </div>
      <div v-if="tableData.length != 0" class="tableBox">
        <el-table
          :data="tableData"
          height="100%"
          @row-click="addToTheContact"
          :span-method="handleSpanMethod"
          ref="tableData"
        >
          <template v-for="(column, index) in tableColumn">
            <el-table-column
              fixed
              :key="index"
              width="138"
              v-if="column.prop == 'name'"
            >
              <div slot="header" class="defaultTips">
                <span class="iconfont_Me icon-user iconStyle"></span>
              </div>
              <template slot-scope="{ row, column, $index }">
                <div
                  v-if="row.isNameFirstWord"
                  class="nameFirstWord"
                  :style="{ width: '32px', height: '32px' }"
                >
                  {{ row.name }}
                </div>
                <div class="basicInfo" v-else @click.stop="readContact(row)">
                  <div class="userPhoto">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="row.profilePhoto"
                      :fit="'fill'"
                    >
                      <div
                        slot="error"
                        class="image-slot"
                        :style="{
                          width: '100%',
                          height: '100%',
                          overflow: 'hidden',
                          background: '#A6A6A6',
                          display: 'flex',
                          'align-items': 'center',
                          'justify-content': 'center',
                        }"
                      >
                        <i class="iconStyle iconfont_Me icon-user-fill"></i>
                      </div>
                    </el-image>
                  </div>
                  <div
                    class="userName"
                    :title="row.name"
                    v-html="row.name"
                  ></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="160"
              :key="index + column.prop"
              v-else-if="column.prop == 'email'"
            >
              <div slot="header" class="defaultTips">
                <span class="iconfont_Me icon-a-Group34998 iconStyle"></span>
              </div>
              <template slot-scope="{ row }">
                <div
                  class="defaultText"
                  v-if="!row.isNameFirstWord"
                  v-html="row.email"
                ></div>
              </template>
            </el-table-column>
            <el-table-column
              :width="phoneColumnWidth"
              :key="index + 1 + column.prop"
              v-else-if="column.prop == 'phone'"
            >
              <div slot="header" class="defaultTips">
                <span class="iconfont_Me icon-a-Group35001 iconStyle"></span>
              </div>
              <template slot-scope="{ row }">
                <div
                  class="phoneText"
                  v-if="!row.isNameFirstWord"
                  v-html="getFirstPhone(row)"
                ></div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :width="column.width"
              :key="`${column.id}-${column.disabled}-${column.width}`"
            >
              <div
                slot="header"
                class="customTips"
                :id="column.id"
                :class="!column.disabled ? 'focus' : ''"
              >
                <template v-if="column.disabled">
                  {{ propCut(column.prop) }}
                  <span
                    class="iconfont_Me icon-edit1 iconStyle"
                    @click.stop="changeHeader"
                    :data-id="column.id"
                  ></span>
                </template>
                <input
                  v-if="!column.disabled"
                  type="text"
                  v-model="column.fieldValue"
                  :ref="'input' + column.id"
                  :data-id="column.id"
                  @blur.stop="fieldInputBlur"
                  @keyup.stop.enter="editField"
                  maxlength="20"
                />
              </div>
              <template slot-scope="{ row }">
                <div class="defaultText" v-if="!row.isNameFirstWord">
                  <span
                    :class="`span-${column.id}`"
                    style="white-space: pre-wrap;-webkit-box-orient: vertical;overflow:hidden; display: -webkit-box;max-width:150px;-webkit-line-clamp: 2;text-overflow: ellipsis;"
                    v-html="renderFieldCell(row, column)"
                  >
                  </span>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <PlaceholderMap v-else :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import PlaceholderMap from "../../../../newVersion/components/placeholderMap";
import * as IMApi from "@/api/im";
import Bus from "@/utils/bus.js";
import MainHeader from "../components/mainHeader";
export default {
  inject: ["control"],
  components: {
    PlaceholderMap,
    MainHeader,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
    showGoBack: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tableheaderInput: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.tableheaderInput.focus();
          });
        } else {
          this.headerValue = "";
        }
      },
      deep: true,
    },
    searchValue: {
      async handler(val, old) {
        if (val != old) {
          this.startSearch();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    Bus.$on("refreshContactsList", async () => {
      this.pageIndex = 1;
      await this.getContactsList();
    });
    await this.getContactsList();
    await this.getContactsFields();
    this.$nextTick(() => {
      this.renderContactsFields();
    });
  },
  beforeDestroy() {
    Bus.$off("refreshContactsList");
  },
  data() {
    return {
      phoneColumnWidth: "auto",
      searchValue: "",
      pageIndex: 1,
      pageSize: 20,
      placeholderIcon: "\ue681",
      headerValue: "",
      addTipsActive: false,
      tableheaderInput: false,
      tableData: [],
      cacheData: [],
      tableColumn: [
        {
          prop: "name",
        },
        {
          prop: "email",
        },
        {
          prop: "phone",
        },
      ],
      fieldValue: "",
      isLoading: false,
      addRepeatTips: false,
      warningTipsCoordinates: {
        top: "0px",
        left: "0px",
      },
      resizeObserver: null,
      resizeTimer: null,
    };
  },
  computed: {
    contactsFields() {
      return this.$store.state.ipTelephone.contactsFields;
    },
    userClickAddToTheContact() {
      return this.$store.state.ipTelephone.userClickAddToTheContact;
    },
  },
  destroyed() {
    this.clearTimer();
    this.resizeObserver.disconnect();
  },
  methods: {
    goBack() {
      this.$emit("goBack");
    },
    clearTimer() {
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = null;
      }
    },
    renderTable() {
      if (this.tableColumn.length > 3) {
        this.phoneColumnWidth = 160;
      }
      this.$nextTick(async () => {
        const wrapper = this.$el.querySelector(".el-table__body-wrapper");
        const fixed = this.$el.querySelector(".el-table__fixed");
        const fixedWrapper = this.$el.querySelector(
          ".el-table__fixed-body-wrapper"
        );
        wrapper.removeEventListener("scroll", this.onTableScroll);
        await this.$forceUpdate();
        wrapper.addEventListener("scroll", this.onTableScroll);
        this.tableColumn.map((column, index) => {
          if (column.id) {
            const columnSpans = this.$el.getElementsByClassName(
              `span-${column.id}`
            );
            if (columnSpans.length != 0) {
              let array = [];
              Array.from(columnSpans).map((span) => {
                array.push(span.offsetWidth);
              });
              const maxWidth = array.sort().reverse()[0];
              if (maxWidth > 200) {
                this.tableColumn[index].width = maxWidth + 40;
              }
            }
          }
        });
        // 优化列表宽度
        try {
          if (this.tableColumn.length > 3) {
            let tableWidth = 458;
            this.tableColumn.forEach((column) => {
              if (column.width) {
                tableWidth += column.width;
              }
            });
            if (tableWidth < wrapper.offsetWidth) {
              const lastWidth = this.tableColumn[this.tableColumn.length - 1]
                .width;
              this.tableColumn[this.tableColumn.length - 1].width =
                lastWidth + wrapper.offsetWidth - tableWidth;
            }
          }
        } catch (error) {}
        this.resizeObserver = new ResizeObserver((entries) => {
          const height = wrapper.offsetHeight - 10;
          fixedWrapper.style.height = `${height}px`;
          fixed.style.height = `${height + 40}px`;
          this.clearTimer();
          this.resizeTimer = setTimeout(() => {
            try {
              const tableBody = wrapper.querySelector(".el-table__body");
              const lastWidth = this.tableColumn[this.tableColumn.length - 1]
                .width;
              if (lastWidth) {
                this.tableColumn[this.tableColumn.length - 1].width =
                  lastWidth + wrapper.offsetWidth - tableBody.offsetWidth;
              }
            } catch (error) {}
          }, 200);
        });
        this.resizeObserver.observe(wrapper);
      });
    },
    async onTableScroll() {
      const wrapper = this.$el.querySelector(".el-table__body-wrapper");
      if (
        wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight &&
        !this.isLoading
      ) {
        this.isLoading = true;
        this.pageIndex++;
        await this.getContactsList();
        this.isLoading = false;
      }
    },
    startSearch: _.debounce(async function() {
      this.pageIndex = 1;
      try {
        this.$refs.tableData.bodyWrapper.scrollTop = 0;
      } catch (error) {}
      await this.getContactsList();
    }, 200),
    handleSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.isNameFirstWord) {
        return {
          rowspan: 1,
          colspan: this.tableColumn.length, // 表格的行数
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
    },
    getFirstPhone(row) {
      if (row.isNameFirstWord) {
        return;
      }
      let phones = row.phone.split(",");
      if (phones.length != 0) {
        return phones[0];
      } else {
        return phone;
      }
    },
    renderContactsFields() {
      if (this.contactsFields.length != 0) {
        this.contactsFields.map((field) => {
          this.tableColumn.push({
            prop: field.fieldName,
            id: field.fieldId,
            disabled: true,
            fieldValue: "",
            width: 220,
          });
        });
        this.renderTable();
      }
    },
    renderFieldCell(row, column) {
      if (row.isNameFirstWord) {
        return;
      }
      let text = "";
      row.fieldVos.map((field) => {
        if (field.fieldId == column.id) {
          text = field.htmlValue || field.fieldValue;
        }
      });
      return text;
    },
    // 获取通讯录列表
    async getContactsList() {
      const result = await IMApi.getContactsList({
        page: this.pageIndex,
        pageSize: this.pageSize,
        searchWords: this.searchValue,
      });
      if (result.code == 200) {
        const isFirstPage = this.pageIndex === 1;
        const hasData = result.data.data.length > 0;
        if (isFirstPage) {
          this.cacheData = [];
          if (!hasData) {
            this.tableData = [];
          } else {
            await this.handleData(result.data.data);
            this.renderTable();
          }
        } else {
          if (!hasData) {
            this.pageIndex > 1 && this.pageIndex--;
          } else {
            await this.handleData(result.data.data);
          }
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    handleData(data) {
      return new Promise(async (resolve, reject) => {
        try {
          // 原始数据
          const contacts = _.cloneDeep(data);
          this.cacheData.push(...contacts);
          this.cacheData = this.filterAnArrayOfObjects(
            this.cacheData,
            "contactsId"
          );
          const result = this.cacheData.reduce((acc, contact) => {
            const existingInitial = acc.find(
              (item) =>
                item.isNameFirstWord &&
                item.nameFirstWord === contact.nameFirstWord
            );
            if (!existingInitial) {
              acc.push({
                isNameFirstWord: true,
                nameFirstWord: contact.nameFirstWord,
                name: contact.nameFirstWord,
              });
            }
            if (this.searchValue != "") {
              const REG_URI = new RegExp(`${this.searchValue}`, "g");
              contact.originalName = contact.name;
              contact.name = contact.name.replace(
                REG_URI,
                `<span style="color:red">${this.searchValue}</span>`
              );
              contact.originalEmail = contact.email;
              contact.email = contact.email.replace(
                REG_URI,
                `<span style="color:red">${this.searchValue}</span>`
              );
              contact.originalPhone = contact.phone;
              contact.phone = contact.phone.replace(
                REG_URI,
                `<span style="color:red">${this.searchValue}</span>`
              );
              if (contact.fieldVos.length != 0) {
                contact.originalFieldVos = _.cloneDeep(contact.fieldVos);
                contact.fieldVos = contact.fieldVos.map((field) => {
                  let fieldValue = field.fieldValue;
                  field.htmlValue = fieldValue.replace(
                    REG_URI,
                    `<span style="color:red">${this.searchValue}</span>`
                  );
                  return field;
                });
              }
            }
            acc.push(contact);
            return acc;
          }, []);
          this.tableData = result;
          await this.$forceUpdate();
          resolve();
        } catch (error) {
          this.tableData = [];
          this.searchValue = "";
          await this.$forceUpdate();
          resolve();
        }
      });
    },
    // 获取自定义标题
    async getContactsFields() {
      const result = await IMApi.getContactsFields();
      if (result.code == 200) {
        this.$store.commit("setContactsFields", result.data.data);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    startIPTelephone(phone) {
      this.$store.commit("setInterfaceData", { phone: phone });
    },
    changeScreen(type) {
      this.control.changeScreen(type);
    },
    addContact() {
      this.$store.commit("setContactDialogData", { dialog: true, type: "add" });
    },
    giveOriginalValue(data) {
      function assignValue(original, key) {
        if (original) {
          data[key] = original;
        }
      }

      assignValue(data.originalName, "name");
      assignValue(data.originalEmail, "email");
      assignValue(data.originalPhone, "phone");
      assignValue(data.originalFieldVos, "fieldVos");

      return data;
    },
    addToTheContact(row) {
      const data = this.giveOriginalValue(_.cloneDeep(row));
      if (this.userClickAddToTheContact) {
        data.phone += `${data.phone != "" ? "," : ""}${
          this.userClickAddToTheContact.phone
        }`;
        this.$store.commit("setContactDialogData", {
          dialog: true,
          type: "edit",
          data: data,
        });
        return;
      }
    },
    readContact(row) {
      if (row.isNameFirstWord) {
        return;
      }
      const data = this.giveOriginalValue(_.cloneDeep(row));
      if (this.userClickAddToTheContact) {
        data.phone += `${data.phone != "" ? "," : ""}${
          this.userClickAddToTheContact.phone
        }`;
        this.$store.commit("setContactDialogData", {
          dialog: true,
          type: "edit",
          data: data,
        });
        return;
      }
      this.$store.commit("setContactDialogData", {
        dialog: true,
        type: "read",
        data: data,
      });
    },
    propCut(prop) {
      let fieldTitle = prop;
      if (fieldTitle.length > 10) {
        fieldTitle = fieldTitle.slice(0, 10) + "...";
      }
      return fieldTitle;
    },
    changeHeader(e) {
      let fieldId = "";
      this.tableColumn.map((column, index) => {
        if (column.id && column.id == e.target.dataset.id) {
          this.tableColumn[index].disabled = false;
          this.tableColumn[index].fieldValue = column.prop;
          fieldId = column.id;
        }
        return column;
      });
      try {
        this.$nextTick(() => {
          if (this.$refs[`input${fieldId}`]) {
            this.$refs[`input${fieldId}`].map((item) => {
              item.focus();
            });
          }
        });
      } catch (error) {}
    },
    async editField(e) {
      const currentColumn = this.tableColumn.filter(
        (column) => column.id == e.target.dataset.id
      )[0];
      if (this.verifyDuplicateLabels(currentColumn.fieldValue)) {
        this.setWarningTipsCoordinates(e.target.dataset.id);
      } else {
        const params = [
          {
            fieldId: e.target.dataset.id,
            fieldName: currentColumn.fieldValue,
          },
        ];
        const result = await IMApi.modifyContactField(params);
        if (result.code == 200) {
          this.tableColumn.map((column, index) => {
            if (column.id && column.id == e.target.dataset.id) {
              this.tableColumn[index].prop = column.fieldValue;
              this.tableColumn[index].disabled = true;
            }
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    fieldInputBlur(e) {
      this.tableColumn.map((column, index) => {
        if (column.id && column.id == e.target.dataset.id) {
          this.tableColumn[index].disabled = true;
        }
      });
      this.addRepeatTips = false;
    },
    openTableheaderInput() {
      this.tableheaderInput = !this.tableheaderInput;
    },
    // 验证重复标签
    verifyDuplicateLabels(val) {
      return this.tableColumn.some((item) => item.prop == val);
    },
    // 设置弹窗位置
    setWarningTipsCoordinates(eventName) {
      const inputEvent = document.getElementById(eventName);
      const {
        top: inputClientY,
        left: inputclientX,
        height: inputHeight,
        width: inputWidth,
      } = inputEvent.parentNode.getBoundingClientRect();
      const viewEvent = document.getElementById("contacts-view");
      const {
        top: viewClientY,
        left: viewclientX,
      } = viewEvent.getBoundingClientRect();
      this.addRepeatTips = true;
      this.$nextTick(() => {
        const warningEvent = document.getElementById("warningTips");
        const { width: warningWidth } = warningEvent.getBoundingClientRect();
        const top = `${inputClientY - viewClientY + inputHeight + 10}px`;
        let left;
        if (warningWidth > inputWidth) {
          left = `${inputclientX -
            viewclientX +
            inputWidth -
            warningWidth / 2}px`;
        } else {
          left = `${inputclientX - viewclientX + inputWidth / 2}px`;
        }
        this.warningTipsCoordinates = {
          top,
          left,
        };
      });
    },
    async addTableheader() {
      this.addRepeatTips = false;
      if (this.verifyDuplicateLabels(this.headerValue)) {
        this.setWarningTipsCoordinates("addTipsInput");
        return;
      }
      if (this.headerValue != "") {
        const result = await IMApi.addContactField([this.headerValue]);
        if (result.code == 200) {
          this.tableColumn.push({
            prop: result.data.data[0].fieldName,
            id: result.data.data[0].fieldId,
            disabled: true,
            fieldValue: "",
            width: 220,
          });
          await this.getContactsFields();
          await this.$forceUpdate();
          this.tableheaderInput = false;
          this.renderTable();
          try {
            setTimeout(() => {
              const wrapper = this.$el.querySelector(".el-table__body-wrapper");
              const tableBody = this.$el.querySelector(".el-table__body");
              wrapper.scrollTo(tableBody.offsetWidth * 2, 0);
            }, 100);
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.contactsView
  width: 100%;
  height: 100%;
  background: #fff;
  user-select: none;
  display: flex;
  flex-direction: column;
  overflow hidden;
  position: relative;
  .mask
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background: #FFFFFF;
  .viewHeader
    width: 100%;
    overflow: hidden;
    line-height: 54px !important;
    height: 54px !important;
    background: #f9f9f9;
    border-right: 1px solid #eee;
    position: relative;
    text-align: center;
    flex-shrink: 0;
    z-index: 5;
    .headerTitle
      color: #1A1A1A;
      margin: 0 auto;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
    .imelink-miniscreen, .imelink-fullscreen
      z-index: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      line-height: 24px;
      position: absolute;
      right: 6px;
      top: 6px;
      cursor: pointer;
      color: #1a1a1a !important;
      &:hover
        background: rgba(0, 0, 0, 0.1);
    .imelink-miniscreen
      right: 30px;
  .viewMain
    width: 100%;
    flex: 1;
    min-height: 0;
    background: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .warningTips
      min-width: 172px;
      white-space: nowrap;
      height: 24px;
      border-radius: 12px;
      background: rgba(0,0,0,0.5);
      color: white;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      position: absolute;
      z-index: 10;
      transform: translate3d(-50%,0,0);
      box-sizing: border-box;
      padding: 0 10px;
    .searchBox
      width: 100%;
      padding-top: 20px;
      height: 43px;
      flex-shrink: 0;
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .goBack
        height: 20px;
        width: 20px;
        margin: 0 0 0 20px;
        img
          width: 20px;
          height: 20px;
      .searchInput
        height: 40px;
        width: 300px;
        margin-left: 20px;
        border-radius: 20px;
        background: #F1F3F4;
    .addTips
      height: 26px;
      max-width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      top: 82px;
      right: 24px;
      z-index: 5;
      flex-direction: row-reverse;
      .active
        border: 1px solid #67C971;
      .tipButton
        height: 26px;
        min-width 70px;
        max-width: 130px
        border-radius: 13px;
        background: #F4FCF8;
        box-sizing: border-box;
        padding 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor pointer;
        position: relative;
        &:focus
          border: 1px solid #67C971;
        .iconStyle
          color: #3c6;
          font-size: 20px;
        .addIconStyle
          color #8f8f8f;
          font-size: 12px;
          font-weight: bold;
    .addContactStyle
      width: 170px;
      height: 43px;
      background: #ffffff;
      border-radius: 21.5px;
      box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.15);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 24px;
      top: 20px;
      font-size: 14px;
      color: #333333;
      z-index: 5;
      .iconBox
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid #33CC66;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        .iconStyle
          font-size: 16px;
          color: #33CC66;
          font-weight: bold;
    .tableBox
      width: 100%;
      flex: 1;
      min-height: 0;
      box-sizing: border-box;
      padding-top: 17px;
      .nameFirstWord
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      .basicInfo
        display: flex;
        height: 32px;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        .userPhoto
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow hidden;
          background: #F0F0F0;
          margin-right: 10px;
          .iconStyle
            font-size: 22px;
        .userName
          height: 32px;
          flex: 1;
          min-width: 0;
          line-height: 32px;
          color #666666;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
      .defaultText
        min-height: 20px;
        width: 100%;
        line-height: 20px;
        color #666666;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      .phoneText
        height: 32px;
        width: 100%;
        line-height: 32px;
        font-size: 14px;
        color: #33CC66;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor pointer;
        span
          text-decoration: underline;
      .defaultTips
        border-radius: 50%
        width: 30px;
        height: 30px;
        background: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle
          font-size: 20px;
          color #333333;
          font-weight: 500;
      .focus
        background: #F4FCF8;
        border: 1px solid #67C971;
      .customTips
        height: 26px;
        border-radius: 13px;
        background: #F5F5F5;
        padding 0px 7px 0 13px;
        box-sizing: border-box;
        font-size: 14px;
        color #000000;
        min-width: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        white-space:nowrap;
        position: relative;
        input
          border: none;
          background-color: transparent;
          height: 26px;
          line-height: 26px;
          padding: 0;
          text-align: center;
          max-width: 100%;
          font-size: 14px;
        &:hover
          .iconStyle
            opacity 1;
        .iconStyle
          cursor pointer;
          margin-left: 1px;
          font-size: 18px;
          color #33CC66;
          opacity 0;
</style>
<style lang="stylus">
.contactsView
  .viewMain
    .searchInput
      .el-input
        .el-input__inner
          font-family 'iconfont_Me';
          caret-color #3E3E3E;
          border: none;
          background-color: transparent;
          height: 40px;
          line-height: 40px;
    .addTips
      .el-input
        .el-input__inner
          border: none;
          background-color: transparent;
          height: 26px;
          line-height: 26px;
          padding: 0;
          text-align: center;
    .tableBox
      .el-table .cell
        padding-left: 20px;
      .el-table__row > td[colspan]:not([colspan="1"])
        background: #F6F6F6;
        height: 30px !important;
        padding: 0 !important;
      thead
        .el-table__cell
          padding 0 0 8px 0;
      .el-table__fixed
        &::before
          display: none;
      .el-table__body-wrapper
        &::-webkit-scrollbar
          width: 10px;
          height: 10px;
        &::-webkit-scrollbar-thumb
          border-radius: 5px;
          box-shadow: inset 0 0 5px rgba(217,217,217,0.2);
          background: #D9D9D9;
</style>
