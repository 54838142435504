var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contactsView" },
    [
      _vm.tableData.length == 0 && _vm.searchValue == ""
        ? _c(
            "div",
            { staticClass: "mask" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 1 } })],
            1
          )
        : _vm._e(),
      _vm.showHeader ? [_c("MainHeader")] : _vm._e(),
      _c(
        "div",
        { staticClass: "viewMain", attrs: { id: "contacts-view" } },
        [
          _vm.addRepeatTips
            ? _c(
                "div",
                {
                  staticClass: "warningTips",
                  style: _vm.warningTipsCoordinates,
                  attrs: { id: "warningTips" }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("contactsRepeatTips")) + "\n    "
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "searchBox" }, [
            _vm.showGoBack
              ? _c(
                  "div",
                  { staticClass: "goBack", on: { click: _vm.goBack } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../../assets/images/email/goBack.png")
                      }
                    })
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "searchInput" },
              [
                _c("el-input", {
                  attrs: { placeholder: _vm.placeholderIcon },
                  model: {
                    value: _vm.searchValue,
                    callback: function($$v) {
                      _vm.searchValue = $$v
                    },
                    expression: "searchValue"
                  }
                })
              ],
              1
            )
          ]),
          !this.userClickAddToTheContact && _vm.tableData.length != 0
            ? _c("div", { staticClass: "addTips" }, [
                _c(
                  "div",
                  {
                    staticClass: "tipButton",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openTableheaderInput.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "iconfont_Me icon-tag2 iconStyle"
                    }),
                    _c("span", {
                      staticClass: "iconfont_Me icon-plus addIconStyle"
                    })
                  ]
                ),
                _vm.tableheaderInput
                  ? _c(
                      "div",
                      {
                        staticClass: "tipButton",
                        class: { active: _vm.addTipsActive }
                      },
                      [
                        _c("el-input", {
                          ref: "tableheaderInput",
                          attrs: { maxlength: "20", id: "addTipsInput" },
                          on: {
                            focus: function($event) {
                              $event.stopPropagation()
                              _vm.addTipsActive = true
                            },
                            blur: function($event) {
                              $event.stopPropagation()
                              _vm.addTipsActive = false
                              _vm.tableheaderInput = false
                              _vm.addRepeatTips = false
                            }
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.stopPropagation()
                              return _vm.addTableheader.apply(null, arguments)
                            }
                          },
                          model: {
                            value: _vm.headerValue,
                            callback: function($$v) {
                              _vm.headerValue = $$v
                            },
                            expression: "headerValue"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          !this.userClickAddToTheContact
            ? _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "addContactStyle",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.addContact.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._m(0),
                  _vm._v("\n      " + _vm._s(_vm.$t("addContact")) + "\n    ")
                ]
              )
            : _vm._e(),
          _vm.tableData.length != 0
            ? _c(
                "div",
                { staticClass: "tableBox" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableData",
                      attrs: {
                        data: _vm.tableData,
                        height: "100%",
                        "span-method": _vm.handleSpanMethod
                      },
                      on: { "row-click": _vm.addToTheContact }
                    },
                    [
                      _vm._l(_vm.tableColumn, function(column, index) {
                        return [
                          column.prop == "name"
                            ? _c(
                                "el-table-column",
                                {
                                  key: index,
                                  attrs: { fixed: "", width: "138" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ row, column, $index }) {
                                          return [
                                            row.isNameFirstWord
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "nameFirstWord",
                                                    style: {
                                                      width: "32px",
                                                      height: "32px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(row.name) +
                                                        "\n              "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "basicInfo",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.readContact(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "userPhoto"
                                                      },
                                                      [
                                                        _c(
                                                          "el-image",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                              height: "100%"
                                                            },
                                                            attrs: {
                                                              src:
                                                                row.profilePhoto,
                                                              fit: "fill"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "image-slot",
                                                                style: {
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                  overflow:
                                                                    "hidden",
                                                                  background:
                                                                    "#A6A6A6",
                                                                  display:
                                                                    "flex",
                                                                  "align-items":
                                                                    "center",
                                                                  "justify-content":
                                                                    "center"
                                                                },
                                                                attrs: {
                                                                  slot: "error"
                                                                },
                                                                slot: "error"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconStyle iconfont_Me icon-user-fill"
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", {
                                                      staticClass: "userName",
                                                      attrs: {
                                                        title: row.name
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          row.name
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "defaultTips",
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont_Me icon-user iconStyle"
                                      })
                                    ]
                                  )
                                ]
                              )
                            : column.prop == "email"
                            ? _c(
                                "el-table-column",
                                {
                                  key: index + column.prop,
                                  attrs: { width: "160" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ row }) {
                                          return [
                                            !row.isNameFirstWord
                                              ? _c("div", {
                                                  staticClass: "defaultText",
                                                  domProps: {
                                                    innerHTML: _vm._s(row.email)
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "defaultTips",
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont_Me icon-a-Group34998 iconStyle"
                                      })
                                    ]
                                  )
                                ]
                              )
                            : column.prop == "phone"
                            ? _c(
                                "el-table-column",
                                {
                                  key: index + 1 + column.prop,
                                  attrs: { width: _vm.phoneColumnWidth },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ row }) {
                                          return [
                                            !row.isNameFirstWord
                                              ? _c("div", {
                                                  staticClass: "phoneText",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.getFirstPhone(row)
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "defaultTips",
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont_Me icon-a-Group35001 iconStyle"
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "el-table-column",
                                {
                                  key: `${column.id}-${column.disabled}-${column.width}`,
                                  attrs: { width: column.width },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ row }) {
                                          return [
                                            !row.isNameFirstWord
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "defaultText"
                                                  },
                                                  [
                                                    _c("span", {
                                                      class: `span-${column.id}`,
                                                      staticStyle: {
                                                        "white-space":
                                                          "pre-wrap",
                                                        "-webkit-box-orient":
                                                          "vertical",
                                                        overflow: "hidden",
                                                        display: "-webkit-box",
                                                        "max-width": "150px",
                                                        "-webkit-line-clamp":
                                                          "2",
                                                        "text-overflow":
                                                          "ellipsis"
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.renderFieldCell(
                                                            row,
                                                            column
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "customTips",
                                      class: !column.disabled ? "focus" : "",
                                      attrs: { slot: "header", id: column.id },
                                      slot: "header"
                                    },
                                    [
                                      column.disabled
                                        ? [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.propCut(column.prop)
                                                ) +
                                                "\n                "
                                            ),
                                            _c("span", {
                                              staticClass:
                                                "iconfont_Me icon-edit1 iconStyle",
                                              attrs: { "data-id": column.id },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.changeHeader.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        : _vm._e(),
                                      !column.disabled
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: column.fieldValue,
                                                expression: "column.fieldValue"
                                              }
                                            ],
                                            ref: "input" + column.id,
                                            refInFor: true,
                                            attrs: {
                                              type: "text",
                                              "data-id": column.id,
                                              maxlength: "20"
                                            },
                                            domProps: {
                                              value: column.fieldValue
                                            },
                                            on: {
                                              blur: function($event) {
                                                $event.stopPropagation()
                                                return _vm.fieldInputBlur.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.stopPropagation()
                                                return _vm.editField.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  column,
                                                  "fieldValue",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _c("PlaceholderMap", { attrs: { placeholderType: 2 } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "iconBox" }, [
      _c("i", { staticClass: "iconfont_Me icon-plus iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }